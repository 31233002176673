import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/archetypes/MJ/new/king.webp'
import Graph from '../components/graph'
import Mail from '../components/mail'
import MyVideo from '../components/video'
import More from '../components/more'

class Result extends React.Component {



  constructor(props) {

    super(props);
    console.log(props);


    this.state = this.props.location.state;



  }


  componentWillMount() {
    console.log("result page");
    console.log(this.state);
  }



  render() {
    let name = "King";
    return (
      <Layout>
        <Helmet title={name} />
        <HeaderGeneric text={name} subtext="Creates order out of chaos"/>
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>



            <h2>{name}</h2>
            
            <p>The King archetype, also known as the Ruler, represents the drive to take control and assert authority over our lives and the world around us. It is the force that pushes us to create order and structure, to lead and to be responsible for the well-being of others. But if left unchecked, the King archetype can turn into a tyrant, a domineering force that crushes the spirit of those around us and ultimately destroys our relationships and our own sense of self.

The temptation to dominate others is a constant threat for those who embody the King archetype. It is easy to fall into the trap of believing that our authority is absolute, that our way is the only way. But true leadership is not about domination, it is about creating a space where all voices can be heard and all perspectives can be respected. It is about fostering an environment where everyone can thrive and reach their full potential.

The task of the King is not an easy one. It requires a deep understanding of human nature and a commitment to the greater good. It requires the ability to navigate the thin line between order and chaos, to strike a balance between holding firm and being flexible. It requires ultimate authority, but also ultimate responsibility.

But the rewards of successfully integrating the King archetype are great. When we are able to create a society that is orderly, just, and productive, we can all live happier, more fulfilled lives. And when we are able to lead with integrity, we can inspire others to do the same.

For those who struggle with the King archetype, I highly recommend reading <a href={"https://jilecek.itch.io/the-inner-world-of-archetypes?utm_source=archetypes&utm_campaign=king"}>"The Inner World of Archetypes: A Guide to Integrating the 12 Jungian Archetypes"</a> e-book. This guide will help you understand the power of the King archetype and give you the tools you need to integrate it in a healthy and productive way. Remember, the fate of your relationships and your own life depends on it. Don't let the King archetype control you, but rather use it to bring out the best in yourself and others.
</p>
<More arch={name}/>
{this.state ? (
  <div>
    <Graph best={this.state.best}  data ={[
{

"id": this.state.names_arr[0],
"label": this.state.names_arr[0],
"value": this.state.vals_arr[0],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[1],
"label": this.state.names_arr[1],
"value": this.state.vals_arr[1],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[2],
"label": this.state.names_arr[2],
"value": this.state.vals_arr[2],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[3],
"label": this.state.names_arr[3],
"value": this.state.vals_arr[3],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[4],
"label": this.state.names_arr[4],
"value": this.state.vals_arr[4],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[5],
"label": this.state.names_arr[5],
"value": this.state.vals_arr[5],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[6],
"label": this.state.names_arr[6],
"value": this.state.vals_arr[6],
"color": "hsl(336, 70%, 50%)"
},
{
"id": this.state.names_arr[7],
"label": this.state.names_arr[7],
"value": this.state.vals_arr[7],
"color": "hsl(60, 70%, 50%)"
},
{
"id": this.state.names_arr[8],
"label": this.state.names_arr[8],
"value": this.state.vals_arr[8],
"color": "hsl(54, 70%, 50%)"
},
{
"id": this.state.names_arr[9],
"label": this.state.names_arr[9],
"value": this.state.vals_arr[9],
"color": "hsl(14, 70%, 50%)"
},
{

"id": this.state.names_arr[10],
"label": this.state.names_arr[10],
"value": this.state.vals_arr[10],
"color": "hsl(92, 70%, 50%)"
},
{
"id": this.state.names_arr[11],
"label": this.state.names_arr[11],
"value": this.state.vals_arr[11],
"color": "hsl(336, 70%, 50%)"
}
]}/>

<Mail best={this.state.best}/>
<MyVideo/>
</div>




) : (
  <div><i>Take the test on the <a href="/">main page</a> to see your archetype breakdown.</i>


  <br/>
<MyVideo/>

  </div>
)}
          </section>

        </div>
      </Layout>
    )
  }
}

export default Result
